.slick-prev::before,
.slick-next::before {
  font-size: 20px;
  color: black;
}

.slick-prev::before {
  display: block;
  transform: translateX(-10%);
}

.slick-slide > div {
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .slick-next::before{
    margin-left : 10px
} */
